.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

img[data-sizes='auto'] {
    display: block;
    width: 100%;
}
